import React from 'react'
import classNames from 'classnames'

const Input = props => {
  const { error, ...atts } = props

  const classes = classNames('input', { 'has-error': error })

  return (
    <div className={classes}>
      <input {...atts} />
    </div>
  )
}

export default Input
