import React from 'react'

import ApartmentForm from './ApartmentForm'
import ApartmentInfo from './ApartmentInfo'

import './apartments.scss'

const Apartments = () => {
  return (
    <div className="container">
      <div className="apartments-wrap">
        <div className="apartments-info-wrap">
          <ApartmentInfo />
        </div>
        <div className="apartments-form-wrap">
          <ApartmentForm />
        </div>
      </div>
    </div>
  )
}

export default Apartments
