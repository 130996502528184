import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Parallax from 'react-rellax'
import Img from 'gatsby-image'

import './hero.scss'

const Hero = ({ className, title, image, quote }) => {
  const classes = classNames('page-hero', className)
  return (
    <section className={classes}>
      <div className="container">{title && <h1>{title}</h1>}</div>

      <div className="content">
        <Parallax speed={-2} className="circle" />

        {image && (
          <div className="image">
            <Img fluid={image.childImageSharp.fluid} />
          </div>
        )}
      </div>

      <div className="container">
        {quote && (
          <div className="quote">
            <blockquote>
              <p>{quote.text}</p>
              <footer>&ndash; {quote.author}</footer>
            </blockquote>
          </div>
        )}
      </div>
    </section>
  )
}

Hero.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  image: PropTypes.object,
  quote: PropTypes.shape({
    text: PropTypes.string,
    author: PropTypes.string,
  }),
}

export default Hero
