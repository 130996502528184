import React from 'react'
import { graphql } from 'gatsby'
import { injectIntl } from 'react-intl'
import { withIntl } from 'i18n'

import Layout from '../components/Layout'
import SEO from '../components/Layout/SEO'
import Hero from '../components/Hero'
import ContentBlock from '../components/ContentBlock'
import Apartments from '../components/Apartments'

const ApartmentsPage = ({ data, intl }) => {
  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: 'apartments' })}
        description={intl.formatMessage({ id: 'description.apartments' })}
      />

      <Hero
        className="apartments"
        title={intl.formatMessage({ id: 'apartments.title' })}
        image={data.image}
        quote={{
          text: intl.formatMessage({ id: 'apartments.quote' }),
          author: intl.formatMessage({ id: 'apartments.quote.author' }),
        }}
      />

      <ContentBlock
        title={intl.formatMessage({ id: 'apartments' })}
        className="apartments"
      >
        <Apartments />
      </ContentBlock>
    </Layout>
  )
}

export default withIntl(injectIntl(ApartmentsPage))

export const ApartmentsQuery = graphql`
  query {
    image: file(relativePath: { eq: "apartments-hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
