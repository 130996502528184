import React from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import AnimateIn from '../../AnimateIn'

import './apartment-info.scss'

const ApartmentInfo = () => {
  return (
    <AnimateIn type="fadeLeft">
      <div className="apartment-info">
        <FormattedMessage tagName="p" id="apartments.info" />
      </div>
    </AnimateIn>
  )
}

export default injectIntl(ApartmentInfo)
